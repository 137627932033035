<template>
  <div class="app-content content ecommerce-application" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <app-breadcrumb :breadcrumbs="breadcrumbs"/>
      <div class="content-body">
        <section>
          <div class="row">
            <div class="col-12 col-xl-9 col-md-8">
              <div class="card">
                <div class="card-body" v-if="product">
                  <div class="form-group">
                    <label class="title">Категория товара *</label>
                    <TreeSelect
                        v-model="form.category_id"
                        :options="categories"
                        :searchable="true"
                        :normalizer="normalizer"
                        @input="changeRequiredOptions"
                        placeholder="Выберите категорию"
                    />
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-4">
                      <div class="form-group">
                        <label for="sku" class="d-block">Артикул (SKU)</label>
                        <div>
                          <input
                              id="sku"
                              :disabled="!form.category_id"
                              v-model="form.sku"
                              placeholder="Введите Артикул"
                              class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-8">
                      <div class="form-group">
                        <label for="name" class="d-block">Название</label>
                        <div>
                          <input
                              id="name"
                              :disabled="!form.category_id"
                              v-model="form.name"
                              placeholder="Введите название"
                              class="form-control"
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="url" class="d-block">Описание</label>
                    <div>
                      <textarea type="text" class="form-control" v-model="form.description" rows="6"/>
                    </div>
                  </div>
                  <ProductOptionsForm
                      @refresh="changeOptions"
                      :initCategoryId="form.category_id"
                      :categoryId="form.category_id"
                      :dictionaries="product.product_dictionaries"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-4 col-xl-3 invoice-actions">
              <div class="card">
                <div class="card-body">
                  <button class="btn btn-primary btn-block" @click="updateProduct">Сохранить</button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>
<script>
import AppBreadcrumb from '@core/layouts/components/AppBreadcrumb.vue';
import TreeSelect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import { subset } from '@/helpers/formatters';
import ProductOptionsForm from '@/views/products/ProductOptionsForm.vue';

export default {
  name: 'ProductUpdate',
  components: {
    ProductOptionsForm, TreeSelect, AppBreadcrumb,
  },
  data() {
    return {
      breadcrumbs: [],
      form: {
        category_id: null,
        name: null,
        sku: null,
        description: null,
      },
      options: null,
      product: null,
      searchQuery: null,
      categories: [],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
          children: node.all_children,
        };
      },
      productOptions: [],
    };
  },
  async mounted() {
    await this.getProduct();
    await this.getCategories();
    await this.initProduct();
    await this.getDictionaries();
  },
  methods: {
    async getProduct() {
      this.product = (await this.$api.products.get(this.$route.params.id)).data;
    },
    async getDictionaries() {
      this.productOptions = (await this.$api.categories.productOptions(this.form.category_id)).data;
    },
    changeOptions(options) {
      this.options = options;
    },
    changeRequiredOptions() {
      this.getDictionaries();
    },
    async initProduct() {
      const info = subset(this.product, ['category_id', 'name', 'sku', 'description']);
      this.form.category_id = info.category_id;
      this.form.name = info.name;
      this.form.sku = info.sku;
      this.form.description = info.description;
    },
    async getCategories() {
      this.categories = (await this.$api.categories.select()).data;
    },
    async updateProduct(e) {
      const error = this.validate();

      if (error) {
        this.$toast.error(error);
        e.preventDefault();
        return;
      }

      const data = { ...this.form };
      data.options = this.options;

      if (this.$route.params.id) {
        try {
          await this.$api.products.update(this.$route.params.id, { ...data });
          this.$bvToast.toast('Товар успешно обновлен', {
            title: 'Успех',
            variant: 'success',
            solid: true,
          });
          await this.$router.push({ name: 'product-details', params: { id: this.$route.params.id } });
        } catch (modalError) {
          this.$bvToast.toast(modalError.response.data.details.message, {
            title: 'Ошибка',
            variant: 'danger',
            solid: true,
          });
        }
      }
    },
    validate() {
      if (!this.form.name) {
        return 'Имя товара не может быть пустым';
      }

      if (!this.form.category_id) {
        return 'Категория товара не может быть пустым';
      }

      if (!this.form.sku) {
        return 'Артикул товара не может быть пустым';
      }
      let error = null;
      this.productOptions.filter((option) => option.required).forEach((option) => {
        if (option.input_type === 'multi_select') {
          if (this.options[option.id] === undefined || !this.options[option.id].length) {
            error = `${option.name} не могут быть пустыми`;
          }
        }

        if (option.input_type === 'input') {
          if (this.options[option.id] === undefined || !this.options[option.id]) {
            error = `${option.name} не может быть пустыми`;
          }
        }

        if (option.input_type === 'select') {
          if (this.options[option.id] === undefined || !this.options[option.id]) {
            error = `Поле ${option.name} не может быть пустым`;
          }
        }
      });

      return error;
    },
  },
};
</script>
<style scoped lang="scss">

</style>
